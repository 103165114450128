.p-forget {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#basic > div > div > div > div > div {
  display: flex;
  gap: 2vw;
}
.buttons {
  width: 52vw;
}

.two-button-conf-from {
  width: 32vw;
  margin-right: 3vw;
}
.btn-navig {
  width: 100%;
  background: #000000;
  box-shadow: 0px 4.79814px 31.1879px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  height: 6vh;
  max-height: 6vh;
}
/* #basic > div > div > div > div > div > button:nth-child(1) {
    max-height: 7vh;
  }
  #basic > div > div > div > div > div > button:nth-child(2) {
    max-height: 7vh;
  } */
