body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Inter',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url('./assets/fonts/Inter/static/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

::-webkit-scrollbar {
  width: 10px !important;
  height: 10px !important;
  padding-left: 150px !important;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 45px #e7e5e5 !important;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px !important;
  border-radius: 10px  !important;
  background: #c6c6c6 !important;
}

/* Firefox */
html {
  scrollbar-width: auto;
  scrollbar-color: #888 #f5f5f5;
}
