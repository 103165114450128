.changePasswd-form {
  width: 52vw;
  /* display: block; */
  margin-left: 18vw;
  display: flex;
  flex-direction: column;
  gap: 4vh;
}
.btn-change {
  width: 100%;
  background: #000000;
  box-shadow: 0px 4.79814px 31.1879px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  height: 6vh;
  max-height: 6vh;
}
