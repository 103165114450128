.preloader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 0;

  &__loaderLogo {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    height: 25vh;

    &__progressBar {
      width: 766px;
      padding-left: 20px;
      padding-top: 57px;
    }
  }
}
