.confirm-container {
  /* background-color: brown; */
  padding: 4vw 10vw 4vw 10vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.confirm-logos {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6vh;
}
.mail-sent {
  width: 50%;
}
.confirmation-p {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 40px;
}
.second-p {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height */

  text-align: center;
}
.button-conf-from {
  width: 18vw;
  margin-left: 6vw;
}
#basic > div > div > div > div > div > button {
  background-color: black !important;
}
.btn-confirm {
  width: 100%;
  background: #000000;
  box-shadow: 0px 4.79814px 31.1879px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  height: 6vh;
  max-height: 6vh;
}
