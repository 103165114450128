.elementCell {
  display: flex;
}
.ajoutElementCell {
  position: absolute;
  padding-top: 40px;
}
.ajoutElementCell:hover {
  cursor: pointer;
}
.ajoutElementCellCat {
  position: absolute;
  padding-top: 30px;
}
.ajoutElementCellCat:hover {
  cursor: pointer;
}
.ajoutElementCellCatgeory {
  position: relative;
}
.addElement {
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.addElement input {
  width: 100%;
}

.btdelete {
  background-color: transparent;
  height: 26px;
}
.btdelete img {
  width: 24px;
  height: 24px;
}
.btedit {
  background-color: transparent;
  border: transparent;
  cursor: pointer;
}
.contentDelete {
  cursor: pointer;
}
.contentStatut {
  cursor: pointer;
}

.elementgrapheCompta {
  background-color: rgba(255, 255, 255, 0.3);
}
.elementgraphe {
  background-color: rgba(51, 153, 204, 0.5);
}
.elementgraphe:hover {
  background-color: gray;
}
.elementPointed {
  background-color: rgba(51, 204, 0, 0.9);
}
.elementEngaged {
  background-color: rgba(96, 223, 54, 0.7);
}
.elementProvisional {
  background-color: rgba(51, 204, 0, 0.3);
}
.categoryName {
  background-color: red;
}
.btdelteCategory {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
.contentPopver {
  display: flex;
  justify-content: space-between;
}
.labelElement {
  overflow: hidden;
  text-overflow: ellipsis;
}
.elementgrapheFoncé {
  background-color: rgba(16, 16, 230, 0.5);
}
