.search-input {
  width: 230;
  height: 37px;
  border: 0.5px solid #000000;
  ::placeholder {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 23px;
    color: #000000;
  }
  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}
.ant-input-affix-wrapper {
  border-radius: 0;
}
