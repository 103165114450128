.legend {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 9px;
  gap: 8.77px;
  border: 1.46169px solid #e5e5ef;
  border-radius: 3px;
}
.legendFlex {
  display: flex;
  justify-content: space-evenly;
  flex-basis: 30%;
  padding: 25px;
}
.responsiveContainer {
  background: #ffffff;
  box-shadow: 0px 2.92337px 8.77011px rgba(13, 10, 44, 0.08);
  border-radius: 29.2337px;
}

.filterSoldeChart {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  padding-right: 20px;
  padding-left: 20px;
}
.spanMode {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-transform: capitalize;
  color: #000000;
}
