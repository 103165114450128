.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th {
  border-right: 3px solid #f5f5f5;
}

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td {
  border-right: 3px solid #f5f5f5;
}

/* .ant-table-tbody > tr > td {
  border-bottom: 10px solid #f0f0f0;
} */