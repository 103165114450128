.option {
  display: flex;

  span {
    padding-left: 20px;
  }
}
.ant-form-item-label > label {
  width: 296px;
  height: 18px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #333333;
}
.ant-form-item {
  margin-bottom: 10px;
}
.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.btn_Confirmation {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 10px;
  Button {
    width: 250px;
    height: 35px;
    border: 0.5px solid #000000;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    letter-spacing: 0.05em;
  }
}
.annuler {
  background-color: #f2f2f2;
  color: #000000;
}
.valider {
  background-color: #000000;
  color: #ffffff;
}
.btn_add {
  display: flex;
  Button {
    height: 35px;
    width: 260px;
    border: 0.5px solid #000000;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    letter-spacing: 0.05em;
  }
}
.add {
  color: #000000;
  background-color: #f2f2f2;
}
.ant-modal-footer {
  background-color: #f2f2f2;
}
.ant-select-selection-placeholder {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: #4f4f4f;
}
