.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__left {
    display: flex;
    align-items: center;
    width: 100%;

    &__datePicker {
      background: #ffffff;
      border: 0.5px solid #000000;

      .rangePicker {
        width: 265px;
        height: 37px;
        ::placeholder {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 19px;
          color: #000000;
        }
      }
    }
  }

  &__right {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    &__filter {
      border: solid 0.5px;
      padding: 10px 14px;
      cursor: pointer;
    }

    &__filterActive {
      display: flex;
      align-items: center;
      justify-content: space-around;
      cursor: pointer;

      &__modeFilterActiveTreso {
        display: flex;
        justify-content: space-between;
        width: 70%;
      }

      &__modeFilterActiveCompta {
        display: flex;
        justify-content: space-between;
        width: 70%;
      }
    }

    &__mode {
      display: flex;
      justify-content: space-around;
      flex-basis: 10%;
    }

    &__InputSearch {
      width: 230;
      height: 37px;
    }
  }
}
.ant-picker .ant-picker-input span.ant-picker-suffix,
.ant-picker.ant-picker-range span.ant-picker-suffix {
  margin-left: 1px;
  margin-right: 10px;
  color: #000000;
  order: 0;
}

.popup-ajouterEcriture {
  display: flex;
  justify-content: space-between;
  align-items: center;
  Button {
    width: 100px;
  }
}

.relle {
  background-color: rgba(51, 153, 204, 0.5);
  width: 100%;
  span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    line-height: 11px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #000000;
  }
}

.simulation {
  width: 100%;
  span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    line-height: 11px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #000000;
  }
}
.previsionnel {
  background-color: rgba(51, 204, 0, 0.3);
  width: 100%;
  span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    line-height: 11px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #000000;
  }
}

.engage {
  background-color: rgba(96, 223, 54, 0.7);
  width: 100%;
  span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    line-height: 11px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #000000;
  }
}
.pointe {
  background-color: rgba(51, 204, 0, 0.9);
  width: 100%;
  span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    line-height: 11px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #000000;
  }
}
.titlePopover {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
}

.ant-popover-placement-bottomLeft .ant-popover-arrow {
  left: 10px;
}
.ant-popover-arrow-content {
  --antd-arrow-background-color: #dedfde;
}

.ant-tooltip-placement-bottomLeft .ant-tooltip-inner {
  left: 10px;
  background-color: #f2f2f2 !important;
  color: #000000 !important;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
}
.ant-tooltip-arrow-content {
  --antd-arrow-background-color: #f2f2f2;
}

.switchHeader {
  display: flex;
  padding-left: 10px;
  width: 100%;
}
.syntheseSwitch {
  background-color: #f8f8f8;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 11px;
}
.syntheseSwitch span {
  padding-right: 10px;
  color: #000000;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
}

.objectifSwitch {
  background-color: #f8f8f8;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
}

.objectifSwitch span {
  padding-right: 10px;
  color: #000000;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
}
.switch {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}
.ant-switch-checked {
  background-color: #000000;
}
.ant-select-dropdown {
  width: 100px;
}
.selectObjective:not(.ant-select-customize-input) .ant-select-selector {
  background: #f8f8f8;
}
.objectiveLabel {
  display: flex;
  justify-content: space-between;
  z-index: 9999;
}
