.home {
  display: flex;
  background-color: #f5f5f5;

  &__sideBar {
    background-color: #ffffff;
    box-shadow: 0px 4px 10px 5px rgba(219, 215, 215, 0.19);
    border-radius: 0px;    
    width: 4%;
    height: 100vh;
  }

  &__container {
    display: flex;
    flex-direction: column;
    width: 96%;

    &__headerContainer {
      display: flex;
      align-items: center;
      width: 100%;
      height: 70px;
      background-color: #ffffff;
    }

    &__table {
      padding: 5px;
    }
  }
}
