.form-builder-wrapper {
  display: flex;
  flex-direction: row;
}
.form_block {
  width: 50%;
  margin: 75px 75px 75px 110px;
  display: flex;
  flex-direction: column;
  row-gap: 10%;
}

.form-builder_logo {
  height: 10%;
}

.image_block {
  width: 50%;
}

.Rectangle_img {
  width: 100%;
}

.text_block {
  height: 20%;
}

.form-builer_inputs_signup {
  height: 100%;
}

.create-account {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 35.9861px;
  line-height: 54px;
  width: 335px;
  height: 54px;
  margin: 0 0 20px 0;
}
.quest {
  width: 369.46px;
  height: 29px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 19.1926px;
  line-height: 29px;
}

#form-builder_text-link {
  width: 369.46px;
  height: 29px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 19.1926px;
  line-height: 29px;
  color: #33cc00;
  text-decoration: underline;
  cursor: pointer;
}

/* #basic > div:nth-child(1) > div {
    display: block;
  } */
div.ant-row.ant-form-item-row {
  display: block;
}
.bottom_form {
  display: flex;
  flex-direction: row;
  width: 67%;
  justify-content: space-between;
}

#basic > div.bottom_form > div.ant-form-item.form-builder_remember > div > div {
  min-width: 135px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-size: 14.3944px;
  line-height: 22px;
  color: #000000;
}

.form-builder_forget {
  min-width: 135px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-size: 14.3944px;
  line-height: 22px;
  color: #000000;
}

#basic > div:nth-child(6) > div > div > div > div > button:focus {
  background-color: black !important;
}

.form-builder_btn {
  width: 100%;
  background: #000000;
  box-shadow: 0px 4.79814px 31.1879px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  height: 6vh;
  max-height: 6vh;
}

.ant-btn-primary {
  border-color: transparent;
}

.ant-btn-primary:hover {
  background-color: black;
  border-color: transparent;
}

#basic > div.bottom_form > div.ant-form-item.form-builder_remember > div > div {
  margin-left: 0px;
}
