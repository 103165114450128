.text-input_container label {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 15.594px;
  line-height: 23px;
  color: #999999;
}

.text-input_container ::placeholder {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 19.1926px;
  line-height: 29px;
}

.text-input {
  border-radius: inherit;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  border-bottom-color: black;
  border-width: 3px;
  /* background: url(../../assets/icon/email.svg) no-repeat scroll 7px 7px; */
  padding-left: 8%;
}

#basic > div:nth-child(1) > div > div.ant-col.ant-col-16.ant-form-item-control > div {
  min-height: 7vh;
}

#basic > div:nth-child(2) > div > div.ant-col.ant-col-16.ant-form-item-control > div {
  min-height: 7vh;
}
#basic > div:nth-child(3) > div > div.ant-col.ant-col-16.ant-form-item-control > div {
  min-height: 7vh;
}

.ant-input:hover {
  border-bottom-color: black;
  border-right-width: 0px;
}

#basic_Email:focus {
  box-shadow: none;
  border-color: black;
}
#basic_Email:hover {
  border-color: black;
}
#basic_Username:focus {
  box-shadow: none;
  border-color: black;
}
#basic_Username:hover {
  border-color: black;
}

#basic > div:nth-child(2) > div > div.ant-col.ant-col-16.ant-form-item-control > div > div > span:hover {
  border-color: black;
  box-shadow: none;
}
#basic > div:nth-child(3) > div > div.ant-col.ant-col-16.ant-form-item-control > div > div > span:hover {
  border-color: black;
  box-shadow: none;
}
#basic > div:nth-child(4) > div > div.ant-col.ant-col-16.ant-form-item-control > div > div > span:hover {
  border-color: black;
  box-shadow: none;
}
#basic > div:nth-child(4) > div > div.ant-col.ant-col-8.ant-form-item-label > label {
  margin-bottom: 2vh;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  outline: none;
  box-shadow: none;
  border-color: black;
}
